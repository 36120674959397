import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../Auth/auth.service';


@Injectable()
export class HttpinterceptorService implements HttpInterceptor {

  constructor(private authService : AuthService, private router: Router) {


   }

  intercept( req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // if (req.body) {
    //   const duplicate = req.clone({ body: req.body.replace(/pizza/gi, '🍕') });
    //   return next.handle(duplicate);
    // }



    console.log(this.router.url +" got to interceptor")

    if(this.router.url != '/login' && !this.router.url.includes('/signup') && !this.router.url.includes('/reset') && (this.router.url != '/vendor/login')) {

        var activatebool = this.authService.isAuthenticated();


         console.log(activatebool)
        if(activatebool == false) {
         //console.log('interceptor logout')

        //  if(this.router.url.includes('vendor')) {
        //   window.location.href = '/vendor/login'
        // }
        // if(this.router.url.includes('user') || this.router.url.includes('rider')) {
        //   window.location.href = 'login'
        // }
        //  else {
        //   window.location.href = 'login'
        //  }

        window.location.href = 'login'


        }


    }
    // else {

    //   if(!req.url.includes('/operatorauth/login') && !req.url.includes('/userresource/') && !req.url.includes('/adminauth/login')) {

    //     //console.log('yes')
    //     // //console.log(req.url)
    //     var activatebool = this.authService.isAuthenticated();

    //     if(activatebool == false) {
    //      //console.log('interceptor logout')
    //      window.location.href = '/'
    //     }

    //     }
    //     else {

    //       //console.log('no')



    //     }


    // }



    return next.handle(req);
  }
}


// how to use interceptors
// https://www.thirdrocktechkno.com/blog/how-to-integrate-interceptor-in-angular-9/
