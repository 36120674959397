export const environment = {
  production: true,
  // baseurl : 'http://192.168.1.30:8000',
  // baseurl : 'http://localhost:8000',
  baseurl : 'https://inventoapi.doorbox.ng',
  // pk: 'pk_test_94f6832af2d6a7999058385435831a93d083493e',
  pk: 'pk_live_d712ce0f57f505992fff492235685e56354b3821',
  apikey: "6414866ebc22d22cf7b25ff3",
  cld: '427587931856823',
  psh: "75dfb276747f9b9a8b30",
  pubk: "LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0KTUlJQ0lqQU5CZ2txaGtpRzl3MEJBUUVGQUFPQ0FnOEFNSUlDQ2dLQ0FnRUF0bTgwVUhubm9CNWx4YjVkVDJSdwpQMEZYY2p2YlpHZlgyMnBpc3pSVWxIS1FCays4aHpCZVZZQVA3RUJnZE5DR0FNQ2R2RTkvRVdyRFltcnVackZzCmlhYklId3FKT3F3VTRMZkl6YUs2Qnhka0QvejkzcGpMdmhCeHBDSWFmTDdqMmtRMzlqQWgvM05DL0ZkM2R5bkQKN2VWVERVelpHcmtNblpNZHB5OHlIRXl2Y3lpa2djOFFpT1NITDl3SUFEUzBTVVdSMFdPNWR0OExLYTJlUlo1bwpmNVlpOS9EV0RDZkF0dUhZUzlzOEVuSWlIcHBlR2FVaWhsZmt3MnBGcDJab3h2OVczVzRLRTZlc2MzVElZRUJPCnk3cnpkNUgwdG5XbGVyNlR6Zm0rSGJZcFQvYXF1bDNBUEU2bUxRVGQxTjIvczJGOEUxNUl0KytzNDhTTmtEWC8KbmM3blJvWER3N25ZZ1dNbW4rS2liUytpaS9UclVJQVplSXJJOG1uSXZMZWw2d0dzd3pBNklXbVhhU0xIRDhrNwpNOGF3QUFBZlRKNnFSaHVTN0FHUExEanFyczdReGFoeVRONHo4ZXBweHVkUWZwUHpZTGpwUWlrMVgxSW1TMExUCm1VdmVUNXg1Y0c3cGFVMXZsRFJtcXhpZEI2cWV5aGpDdlIzUExUZDN4SFl4bFB3OFFJMS9BV09iYTIrelhxYmUKMExiOFk3YkR3a0c4MEU3T0s4NE8zSzN1cC96WjNNQk9nVmR5ajVYSitod1lGT2p2dmV2YUlEOHZmdGVvTUQ2Lwp2VU5sRHpsSVZjaU9HMGJXaUxSRHNYem42Rm85QW1XRHpnN2hiUlBPTG5LK3lmbmlhRlpvbklJU2FyNFM0RGRPCjZHNC9Gai9kWTlhSlVpWkZ3SjJVYWVjQ0F3RUFBUT09Ci0tLS0tRU5EIFBVQkxJQyBLRVktLS0tLQo="
};
