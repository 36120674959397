
  <ion-menu contentId="main-content">
    <ion-header class="ion-no-border">
      <ion-toolbar>

        <ion-item lines="none">
          <ion-label >Hi {{user?.name | titlecase | splitemail }}</ion-label>
        </ion-item>
        <!-- <ion-toggle id="themeToggle" slot="end"></ion-toggle> -->

        <ion-button (click)="Clickthemenew()" fill="clear" color="dark" slot="end">
          <ion-icon name="sunny-outline" *ngIf="darkmodebool === true"></ion-icon>
          <ion-icon name="moon-outline" *ngIf="darkmodebool === false"></ion-icon>
        </ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">

     <div class="usermenu" *ngIf="userviewsbool === true">

      <ion-list>



        <ion-menu-toggle>
         
          <ion-item lines="none" id="profile" class="menuitem"   routerLink="user/tabs/profile">

            <ion-label >
              <ion-icon name="person-outline"></ion-icon> Profile
            </ion-label>
    
           </ion-item>
    
           <ion-item lines="none" id="orders" class="menuitem" routerLink="user/tabs/analytics" *ngIf="user?.type == 'super_v'">
    
            <ion-label >
              <ion-icon name="analytics-outline"></ion-icon> Analytics
            </ion-label>
    
            
           </ion-item>
    
           <ion-item lines="none" id="mymenu" class="menuitem" routerLink="user/tabs/settings" *ngIf="user?.type == 'super_v'">
    
            <ion-label>
              <ion-icon name="restaurant-outline"></ion-icon> Settings
            </ion-label>
    
           </ion-item>
    
           <ion-item lines="none" id="chat" class="menuitem">
    
            <ion-label>
              <a href="https://wa.me/08178298944?text=Hello%20DoorBox!">
    
                <ion-icon name="chatbubbles-outline"></ion-icon> Customer Care
    
              </a>
            </ion-label>
    
           </ion-item>
    
           <!-- <ion-item lines="none" id="settings" class="menuitem">
    
            <ion-label >
              <ion-icon name="settings-outline"></ion-icon> Settings
            </ion-label>
    
           </ion-item> -->

      </ion-menu-toggle>

     

      </ion-list>
     </div>







     <div class="usermenu" *ngIf="vendorviewsbool === true">


      <ion-list>
        <!-- [routerLink]="['user/tabs/profile']" -->

        <ion-item lines="none" id="orders" class="menuitem" href="vendor/tabs/home">

          <ion-label >
            <ion-icon name="home-outline"></ion-icon> Home
          </ion-label>

         </ion-item>

        <ion-item lines="none" id="orders" class="menuitem" href="vendor/tabs/profile">

          <ion-label >
            <ion-icon name="finger-print-outline"></ion-icon> Profile
          </ion-label>

         </ion-item>

         <ion-item lines="none" id="orders" class="menuitem" href="vendor/tabs/menu">

          <ion-label >
            <ion-icon name="fast-food-outline"></ion-icon> Menus
          </ion-label>

         </ion-item>


       <ion-item lines="none" id="chat" class="menuitem">

        <ion-label>

          <a href="https://wa.me/08178298944?text=Hello%20DoorBox!">

            <ion-icon name="chatbubbles-outline"></ion-icon> Customer Care

          </a>

        </ion-label>

       </ion-item>

       <!-- <ion-item lines="none" id="settings" class="menuitem">

        <ion-label >
          <ion-icon name="settings-outline"></ion-icon> Settings
        </ion-label>

       </ion-item> -->

      </ion-list>
     </div>



      <!-- <ion-menu-toggle>
        <ion-button>Click to close the menu</ion-button>
      </ion-menu-toggle> -->
    </ion-content>

    <ion-footer class="ion-padding ion-no-border"  >

    <ion-toolbar >

      <ion-button (click)="logOut()" fill="clear" slot="start" color="dark"><ion-icon name="log-out-outline"></ion-icon>Logout</ion-button>

      </ion-toolbar>
    </ion-footer>

  </ion-menu>
  <div class="ion-page" id="main-content">


    <!-- <ion-header>
      <ion-toolbar>
        <ion-title>Menu</ion-title>
      </ion-toolbar>
    </ion-header> -->
    <!-- <ion-content class="ion-padding">
      <ion-menu-toggle>
        <ion-button>Click to open the menu</ion-button>
      </ion-menu-toggle>
    </ion-content> -->
  </div>


<ion-app>


  <ion-header class="ion-no-border" [ngClass]="userviewsbool == true ? 'show' : 'hide'">
    <ion-toolbar >

      <ion-buttons  slot="start" (click)="toogleMenu()" *ngIf="showbackbutton === false">
        <ion-button color="dark">
          <ion-icon  name="menu-outline" class="iconsize"></ion-icon>
        </ion-button>
      </ion-buttons>

    <ion-buttons slot="start" *ngIf="showbackbutton === true">
      <ion-button (click)="goBack()"><ion-icon name="chevron-back-outline"></ion-icon> back</ion-button>
    </ion-buttons>


    <!-- {{this.oldcount}} {{this.newcartcount}} -->
      <!-- <ion-button fill="clear" size="large" slot="end" color="dark" class="cartbox" href="user/tabs/checkout">

        <small id="itemcount" class="itemcount" *ngIf="cart > 0">{{cart}}</small>
        <ion-icon id="carticon" name="cart-outline"  *ngIf="cart > 0"></ion-icon>
      </ion-button> -->

    
   
      <ion-button (click)="presentControlDate()" color="dark" expand="block" size="default" shape="round" style="margin-right: 10px" *ngIf="currentRoute.includes('user/tabs/order')">

    
            Select Date
       
      
        
          <ion-icon slot="end" name="calendar-outline"></ion-icon>
       
      
      
      </ion-button>

      <!-- <ion-modal  #modal trigger="datetime" [keepContentsMounted]="true" >
        <ng-template>
          <ion-datetime presentation="date" ></ion-datetime>
         
        </ng-template>
      </ion-modal> -->

      <ion-popover  alignment="center"  #popover [isOpen]="isopenDate" (didDismiss)="isopenDate = false"  [keepContentsMounted]="true"  >
        <ng-template  >
            <ion-datetime  (ionChange) = "selectedDate($event)" presentation="date" locale="en-US"  [showDefaultButtons]="false">

              <!-- <span slot="title">Select a date</span> -->
            </ion-datetime>
        </ng-template>
    </ion-popover>

      

      <ion-thumbnail slot="end" style="margin-right: 20px;">
        <!-- <img alt="Silhouette of mountains" src="assets/img/someerrands.jpg" /> -->
        <ion-icon color="primary" name="sparkles-outline" style="font-size: 23px;"></ion-icon>
      </ion-thumbnail>


    </ion-toolbar>
  </ion-header>




  <ion-header class="ion-no-border" *ngIf="riderviewsbool === true">
    <ion-toolbar >

      <ion-buttons  slot="start" (click)="toogleMenu()" *ngIf="showbackbutton === false">
        <ion-button color="dark">
          <ion-icon  name="menu-outline" class="iconsize"></ion-icon>
        </ion-button>
      </ion-buttons>

    <ion-buttons slot="start" *ngIf="showbackbutton === true">
      <ion-button (click)="goBack()"><ion-icon name="chevron-back-outline"></ion-icon> back</ion-button>
    </ion-buttons>



      <!-- <ion-button fill="clear" size="large" slot="end" color="dark" class="cartbox" href="user/tabs/checkout">

        <small class="itemcount" *ngIf="cart > 0">{{cart}}</small>
        <ion-icon name="cart-outline"></ion-icon>
      </ion-button> -->



      <ion-thumbnail slot="end">
        <img alt="doorbox ng" src="assets/img/someerrands.jpg" />
      </ion-thumbnail>


    </ion-toolbar>
  </ion-header>

  <ion-content  >

<!-- getRelatedApps -->
    <!-- hey dashnboards -->


    <ion-router-outlet></ion-router-outlet>

    <!-- <app-tabs></app-tabs> -->

  </ion-content>


   <!-- <div id="printme" >
 
    
    <div *ngFor="let item of someJSONdata">
      <p>Name : {{item?.name}}</p>
      <p>Email : {{item?.email}}</p>
      <p>Number : {{item?.phone}}</p>
    </div>
   </div> -->

   <button id="open-modal" ></button>

  <ion-modal #modal trigger="open-modal"  style="--border-radius: 30px" [canDismiss]="canDismiss" [initialBreakpoint]="button_data.value ? 0.35 : (button_data.type ? 0.5 : 0.35)" [breakpoints]="[0, 0.5, 1]"
  
  [isOpen]="isOpen"

  >
    <ng-template>
      <div class="block">

        <h4 class="ion-text-center" *ngIf="!button_data?.type">
          <ion-text class="ion-text-center" *ngIf="button_data?.value">
            <h3><ion-label *ngIf="button_data?.for == 'order'"> Deduct NGN{{button_data?.value | number:'1.0-0'}}.00</ion-label></h3>
            <h3><ion-label *ngIf="button_data?.for == 'change'"> Return NGN{{button_data?.value | number:'1.0-0'}}.00</ion-label></h3>
           </ion-text>
           <ion-text class="ion-text-center">
            <ion-label>Are you sure?</ion-label>
           </ion-text>
           
        </h4>

        <div *ngIf="button_data?.type =='order'">
          <ion-grid >
            <ion-row class="ion-justify-content-center" >
  
              <lottie-player src="https://lottie.host/b81f6107-1c9f-45a6-afa4-34344440e49d/KH3duVH4lb.json" background="#ffffff" speed="0.8" style="width: 100px; height: 100px" loop  autoplay direction="1" mode="normal"></lottie-player>
     
            </ion-row>
          </ion-grid>

          <div class="ion-text-center">
            <ion-text class="ion-text-center" *ngIf="button_data?.tally">
              <ion-label color="medium">Order created successfully!</ion-label>
             </ion-text>
             <ion-text class="ion-text-center">
              <h2><ion-label>{{button_data?.tally}}</ion-label></h2>
             </ion-text>
             
          </div>

          <ion-button class="mt5"   shape="round" (click)="printDocument(modal, false)"  expand="block"> Print <ion-icon name="print-outline"></ion-icon></ion-button>
          <ion-button class="mt5" (click)="alertButtonClick(modal, true, 'order')"  shape="round" expand="block" color="light" > Close </ion-button>
      

        </div>

        <div *ngIf="button_data?.type =='change'">
          <ion-grid >
            <ion-row class="ion-justify-content-center" >
  
              <lottie-player src="https://lottie.host/b81f6107-1c9f-45a6-afa4-34344440e49d/KH3duVH4lb.json" background="#ffffff" speed="0.8" style="width: 100px; height: 100px" loop  autoplay direction="1" mode="normal"></lottie-player>
     
            </ion-row>
          </ion-grid>

          <div class="ion-text-center">
            <ion-text class="ion-text-center" *ngIf="button_data?.value">
              <ion-label color="medium">Change created successfully!</ion-label>
             </ion-text>
             <ion-text class="ion-text-center">
              <h2><ion-label>NGN{{button_data?.value| number:'1.0-0'}}.00</ion-label></h2>
             </ion-text>
             
          </div>

        </div>

      


        <div *ngIf="!button_data?.type">

          <ion-button class="mt5"  shape="round" (click)="alertButtonClick(modal, true, 'others')"  expand="block"     > Yes</ion-button>
  
          <ion-button class="mtg5" (click)="alertButtonClick(modal, false, 'others')"  shape="round" expand="block" color="dark" > No </ion-button>
      
        </div>
      
        
        <!-- <div *ngIf="button_data?.type == 'order'">

          <ion-button class="btnclass mtg5" (click)="alertButtonClick(modal, false)"  expand="block" color="dark" > Create new order </ion-button>
      
        </div> -->
      
      
       
      </div>
    </ng-template>
  </ion-modal>

</ion-app>
