import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private baseurl = '';

  k  =  localStorage.getItem('k');

  // private jwtHelper = new JwtHelperService();

  constructor(private http: HttpClient) {

    console.log("%what are you looking for?, I can see your ip, get a life bro u dont need this", "color: white; font-size: x-large; background-color: red");

    this.baseurl = environment.baseurl;

  }


  httpOptionstoken = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Accept':   'application/json',
      'Authorization': 'Bearer ' + this.k
    })
  };

  httpOptionstokenbeareronly = {
    headers: new HttpHeaders({
      // 'Content-Type':  'application/json',
      // 'Accept':   'application/json',
      // 'Authorization': 'Bearer ' + this.k
    })
  };

 httpOptionscldimgheader = {
    headers: new HttpHeaders({
      // 'Content-Type':  'multipart/form-data',
      // 'Accept':  'multipart/form-data',
      // 'API_KEY': '717495443357933'
    })
  };

  httpOptionsbasicheaders = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Accept':   'application/json',

    })
  };

  userLoginn(email: any, password: any) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/userauth/login' , {
        email: email,
        password: password
      },
      this.httpOptionsbasicheaders
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }



  riderPendingDeliveries() {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/riderresource/riderpendingdeliveries' , {

      },
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }

  riderupdatestatus() {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/riderresource/riderupdatestatus' , {

      },
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }



  activebooking() {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/riderresource/riderupdatestatus' , {

      },
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }


  riderLogin(email: any, password: any) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/riderauth/login' , {
        email: email,
        password: password
      },
      this.httpOptionsbasicheaders
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }


  activeBookings() {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/riderresource/activebooking' , {

      },
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }


  createReview(data: any) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/userresource/createreview' , data,
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }




  riderAcceptBooking(data: any) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/riderresource/acceptbooking',

        data

      ,
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }


  riderDeliverBooking(data: any) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/riderresource/deliverbooking',

        data

      ,
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }


  riderDeliveredOrdersByDate(data: any) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/riderresource/deliveredordersbydate',

        data

      ,
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }


  riderPayouts(data: any) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/riderresource/riderpayouts',

        data

      ,
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }



  pickedBooking(data: any) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/riderresource/pickupbooking',

        data

      ,
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }










  // userAuthcode(email: any) {

  //   return new Promise(resolve => {
  //     this.http.post(this.baseurl + '/api/userauth/authcodegeneral' , {
  //       email: email
  //     },
  //     this.httpOptionsbasicheaders
  //     ).subscribe((data: any) => {
  //       resolve(data);
  //     }, (err: any) => {
  //       // console(err);
  //       return resolve(err);
  //     });
  //   });

  // }


  generalAuthcode(email: string, account: string, type:string, number?: number) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/adminauth/authcodegeneral' , {
        email: email,
        account: account,
        type: type,
        number: number
      },
      this.httpOptionsbasicheaders
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }


  createanalytics(obj: {}) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/adminauth/createanalytics' , obj,
      this.httpOptionsbasicheaders
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }


  userResetPassword(data: any) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/userauth/resetpasswordv2' ,data,
      this.httpOptionsbasicheaders
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }

  riderResetPassword(data: any) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/riderauth/resetpasswordv2' ,data,
      this.httpOptionsbasicheaders
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }


  riderupdateAccount(data: any) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/riderauth/updateaccount' ,data,
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }











  riderRegister(data: any) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/riderauth/register' , data ,
      this.httpOptionsbasicheaders
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }

  riderAuthcode(email: any) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/riderauth/authcode' , {
        email: email
      },
      this.httpOptionsbasicheaders
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }



  checkUserOrRider(email: string) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/userauth/checkuserorrider' , {
        email: email
      },
      this.httpOptionsbasicheaders
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }


  // getUser() {

  //   return new Promise(resolve => {
  //     this.http.get(this.baseurl + '/api/userauth/user',
  //     this.httpOptionstoken
  //     ).subscribe((data: any) => {
  //       resolve(data);
  //     }, (err: any) => {
  //       // console(err);
  //       return resolve(err);
  //     });
  //   });

  // }


  getRider() {

    return new Promise(resolve => {
      this.http.get(this.baseurl + '/api/riderauth/rider',
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }



  userMenus() {
    return new Promise(resolve => {
      this.http.get(this.baseurl + '/api/userresource/usermenus',
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });
  }



  pricePerKm_User() {
    return new Promise(resolve => {
      this.http.get(this.baseurl + '/api/userresource/priceperkm',
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });
  }


  createError(data: any) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/adminauth/createerror', data,
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }

  userOrders(obj: {}) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/userresource/orders', obj,
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });
  }


  getRequestedLocation() {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/userresource/requestedlocation', {},
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });
  }


  sendCommsUserAuth(obj : {}) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/userauth/sendcomms', obj,
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });
  }


  sendCommsRiderAuth(obj : {}) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/riderauth/sendcomms', obj,
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });
  }

  sendComms(obj : {}) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/userresource/sendcomms', obj,
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });
  }



  disableMenu_User(id: string) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/userresource/disablemenu',

      {
        id: id
      },

      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }


  VerifyMenuV2_User(data: any) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/userresource/verifymenuv2',
      data ,
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }




  user_Topup(data: any) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/userresource/usertopup',
      data ,
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }


  get_user_topups() {

    return new Promise(resolve => {
      this.http.get(this.baseurl + '/api/userresource/usertopups',
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }



  getUserTransactions() {

    return new Promise(resolve => {
      this.http.get(this.baseurl + '/api/userresource/getusertransactions',
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }






  CreateBooking_User(data: any) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/userresource/createbooking',
      data ,
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }


  getVendors_User() {

    return new Promise(resolve => {
      this.http.get(this.baseurl + '/api/userresource/vendors',
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }


  getVendorMenus_User(id: string) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/userresource/vendormenus',

      {
        id: id
      },

      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }


  vendorMenusOrderAgain(ids: string, partner_id: string) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/userresource/vendormenusorderagain',

      {
        ids: ids,
        partner_id : partner_id
      },

      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }



  getVendorMenusItems_User(id: string) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/userresource/vendormenuitems',

      {
        id: id
      },

      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }


  createMenu_User(data: any) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/userresource/createmenu',
      data,

      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }


  operatorlogin(email: any, password: any) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/manufacturerauth/login' , {
        email: email,
        password: password
      }
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });
  }




menusPaidFor(id: string) {

  return new Promise(resolve => {
    this.http.post(this.baseurl + '/api/partnerresource/menuspaidfor' ,

    {
      id: id
    }
,
    this.httpOptionstoken

    ).subscribe((data: unknown) => {
      resolve(data);
    }, (err: unknown) => {
      // console(err);
      return resolve(err);
    });
  });
}

menusPaidFor_Rider(id: string) {

  return new Promise(resolve => {
    this.http.post(this.baseurl + '/api/riderresource/menuspaidfor' ,

    {
      id: id
    }
,
    this.httpOptionstoken

    ).subscribe((data: unknown) => {
      resolve(data);
    }, (err: unknown) => {
      // console(err);
      return resolve(err);
    });
  });
}
menusPaidFor_User(id: string) {

  return new Promise(resolve => {
    this.http.post(this.baseurl + '/api/userresource/menuspaidfor' ,

    {
      id: id
    }
,
    this.httpOptionstoken

    ).subscribe((data: unknown) => {
      resolve(data);
    }, (err: unknown) => {
      // console(err);
      return resolve(err);
    });
  });
}


acceptBooking(status: string, id: string, ) {

  return new Promise(resolve => {
    this.http.post(this.baseurl + '/api/partnerresource/acceptbooking' ,

    {
      id: id,
      status: status
    }
,
    this.httpOptionstoken

    ).subscribe((data: unknown) => {
      resolve(data);
    }, (err: unknown) => {
      // console(err);
      return resolve(err);
    });
  });
}



partner_auth_reset(
  data: any
 ) {

 // this.httpOptionstoken.headers.append('Authorization', 'Bearer ' + this.k)

 return new Promise(resolve => {
   this.http.post(this.baseurl + '/api/manufacturerauth/resetpasswordv2' ,  data
    ,
   this.httpOptionstoken

   ).subscribe((data: unknown) => {
     resolve(data);
   }, (err: unknown) => {
     // console(err);
     return resolve(err);
   });
 });

}


disableMenuOrMenuitem(data: any) {

  return new Promise(resolve => {

    this.http.post(this.baseurl + '/api/partnerresource/disablemenuormenuitem' ,  data,

    this.httpOptionstoken

    ).subscribe((data: unknown) => {
      resolve(data);
    }, (err: unknown) => {
      // console(err);
      return resolve(err);
    });
  });

}


getMenuandMenuItems() {

  return new Promise(resolve => {
    this.http.get(this.baseurl + '/api/partnerresource/menuandmenuitems' ,

    this.httpOptionstoken

    ).subscribe((data: unknown) => {
      resolve(data);
    }, (err: unknown) => {
      // console(err);
      return resolve(err);
    });
  });
}


getPayoutDetailsByDateDashboard_Partner(data: any) {

  return new Promise(resolve => {
    this.http.post(this.baseurl + '/api/partnerresource/getpayoutdetailsbydatedashboard' ,  data,

   this.httpOptionstoken

    ).subscribe((data: unknown) => {
      resolve(data);
    }, (err: unknown) => {
      // console(err);
      return resolve(err);
    });
  });

}



current_operator( ) {

  return new Promise(resolve => {
    this.http.get(this.baseurl + '/api/manufacturerauth/manufacturer' ,

    this.httpOptionstoken

    ).subscribe((data: unknown) => {
      resolve(data);
    }, (err: unknown) => {
      // console(err);
      return resolve(err);
    });
  });

}


updateStatus() {

  return new Promise(resolve => {

    this.http.post(this.baseurl + '/api/partnerresource/updatestatus' ,{},

    this.httpOptionstoken

    ).subscribe((data: unknown) => {
      resolve(data);
    }, (err: unknown) => {
      // console(err);
      return resolve(err);
    });
  });

}


deliverBooking(code: string, id: string, ) {

  return new Promise(resolve => {
    this.http.post(this.baseurl + '/api/partnerresource/deliverbooking' ,

    {
      id: id,
      code: code
    }
,
    this.httpOptionstoken

    ).subscribe((data: unknown) => {
      resolve(data);
    }, (err: unknown) => {
      // console(err);
      return resolve(err);
    });
  });
}

  operator_bookings(date: string) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/partnerresource/allbookings' ,

      {
        date: date
      }
  ,
      this.httpOptionstoken

      ).subscribe((data: unknown) => {
        resolve(data);
      }, (err: unknown) => {
        // console(err);
        return resolve(err);
      });
    });
  }


  updateMenuMenuItem(data: any) {

    return new Promise(resolve => {

      this.http.post(this.baseurl + '/api/partnerresource/updatemenumenuitem' ,  data,

      this.httpOptionstoken

      ).subscribe((data: unknown) => {
        resolve(data);
      }, (err: unknown) => {
        // console(err);
        return resolve(err);
      });
    });

  }




cloudinary_sig(str: string) {


  return new Promise(resolve => {
    this.http.post(this.baseurl+ "/api/partnerresource/cldsig" , {
      str: str
    },
     this.httpOptionstoken

    ).subscribe((data: unknown) => {
      resolve(data);
    }, (err: unknown) => {
      // console(err);
      return resolve(err);
    });
  });
}


credUp( data: any) {

  return new Promise(resolve => {
    this.http.post(this.baseurl + '/api/manufacturerauth/updatebc' , data,

    this.httpOptionstoken

    ).subscribe((data: unknown) => {
      resolve(data);
    }, (err: unknown) => {
      // console(err);
      return resolve(err);
    });
  });

}


deliveryMins(mins: number) {

  return new Promise(resolve => {
    this.http.post(this.baseurl+ "/api/partnerresource/deliverymins" , {
      mins: mins
    },
     this.httpOptionstoken

    ).subscribe((data: unknown) => {
      resolve(data);
    }, (err: unknown) => {
      // console(err);
      return resolve(err);
    });
  });
}

UpdateMenuorPackagingCost(data: any) {

  return new Promise(resolve => {

    this.http.post(this.baseurl + '/api/partnerresource/updatemenuitemamount' ,

    data
    ,

    this.httpOptionstoken

    ).subscribe((data: unknown) => {
      resolve(data);
    }, (err: unknown) => {
      // console(err);
      return resolve(err);
    });
  });

}

createMenuItem(data: any) {

  return new Promise(resolve => {

    this.http.post(this.baseurl + '/api/partnerresource/createmenuitem' ,  data,

    this.httpOptionstoken

    ).subscribe((data: unknown) => {
      resolve(data);
    }, (err: unknown) => {
      // console(err);
      return resolve(err);
    });
  });

}


createMenu(data: any) {

  return new Promise(resolve => {

    this.http.post(this.baseurl + '/api/partnerresource/createmenu' ,  data,

    this.httpOptionstoken

    ).subscribe((data: unknown) => {
      resolve(data);
    }, (err: unknown) => {
      // console(err);
      return resolve(err);
    });
  });

}


  cloudinary_sig_user(str: string) {

    return new Promise(resolve => {
      this.http.post(this.baseurl+ "/api/userresource/cldsig" , {
        str: str
      },
       this.httpOptionstoken

      ).subscribe((data: unknown) => {
        resolve(data);
      }, (err: unknown) => {
        // console(err);
        return resolve(err);
      });
    });
  }



cloudinary_Upload(file: any) {

  return new Promise(resolve => {
    this.http.post("https://api.cloudinary.com/v1_1/dgvwslvzf/auto/upload" , file,
     this.httpOptionscldimgheader

    ).subscribe((data: unknown) => {
      resolve(data);
    }, (err: unknown) => {
      // console(err);
      return resolve(err);
    });
  });
}


cloudinary_Delete(file: any) {

  return new Promise(resolve => {
    this.http.post("https://api.cloudinary.com/v1_1/dgvwslvzf/image/destroy" ,

     file,
     this.httpOptionscldimgheader


    ).subscribe((data: unknown) => {
      resolve(data);
    }, (err: unknown) => {
      // console(err);
      return resolve(err);
    });
  });
}

  getCategories() {

    return new Promise(resolve => {
      this.http.get(this.baseurl + '/api/partnerresource/getcategories' ,

     this.httpOptionstoken

      ).subscribe((data: unknown) => {
        resolve(data);
      }, (err: unknown) => {
        // console(err);
        return resolve(err);
      });
    });

  }





  // .  new project 

  Login(email: any, password: any) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/vauth/login' , {
        email: email,
        password: password
      },
      this.httpOptionsbasicheaders
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }


  getVendor() {

    return new Promise(resolve => {
      this.http.get(this.baseurl + '/api/vauth/get',
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }

  getMarkets(data?: any) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/market/marketsbydate',{data},
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }


  getMarket(data: any) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/market/get', data, 
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }

  createMarket(market: any) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/market/create',market,
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }

  createOrder(order: any) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/order/create',order,
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }


  getSubVnedor() {

    return new Promise(resolve => {
      this.http.get(this.baseurl + '/api/vresources/getsubvendors',
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }


  getProducts() {

    return new Promise(resolve => {
      this.http.get(this.baseurl + '/api/product/all',
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }



  getOrderResources(data?: any) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/vresources/orderresourcesbyidanddate' ,data,
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }


  filterDateProductTime(data?: any) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/analytics/filterdateproducttime' ,data,
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }


  marketsBydate(data?: any) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/market/marketsbydate' ,data,
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }



  getProfile() {

    return new Promise(resolve => {
      this.http.get(this.baseurl + '/api/vauth/get' ,
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }


  getSubsriptions() {

    return new Promise(resolve => {
      this.http.get(this.baseurl + '/api/subscription/getsubscriptions' ,
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }



  getSubvendors() {

    return new Promise(resolve => {
      this.http.get(this.baseurl + '/api/vresources/getsubvendors' ,
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }


  createSubVendor(data: any) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/vauth/signupsub' ,data,
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }


  createProduct(data: any) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/product/create' ,data,
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }



  updateSubVendor(data: any) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/vauth/updatevendor' ,data,
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }


  updateSuperVendor(data: any) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/vauth/updatesuper' ,data,
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }


  createSubscription(data: any) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/subscription/subscribe' ,data,
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }



  

  



  toggleSubVendor(id: string) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/vauth/togglevendor' ,{id},
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }


  toggleProduct(id: string) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/product/toggle' ,{id},
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }

  toggleMarket(id: string) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/market/toggle' ,{id},
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }


  updateMarket(data: any) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/market/updatemarket' ,data,
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }

  

  toggleTag(id: string) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/balance/toggletag' ,{id},
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }



  creaateBalance(data: any) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/balance/create' ,data,
      this.httpOptionstoken
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }

  userRegister(data: any) {

    return new Promise(resolve => {
      this.http.post(this.baseurl + '/api/vauth/signup' , data ,
      this.httpOptionsbasicheaders
      ).subscribe((data: any) => {
        resolve(data);
      }, (err: any) => {
        // console(err);
        return resolve(err);
      });
    });

  }
  
  

  
  
  

}
