import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitemail'
})
export class SplitemailPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {


    return value?.split('@')[0];

  }

}
