import { Location , DatePipe} from '@angular/common';
import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Device } from '@capacitor/device';
import { MenuController, AlertController,Platform, IonModal } from '@ionic/angular';
import { take, takeLast } from 'rxjs';
import printJs from 'print-js'
// import { Geolocation, Position } from '@capacitor/geolocation';
// import * as LottiePlayer from "@lottiefiles/lottie-player";

import { ApiService } from './Services/Apis/api.service';
import { DataService } from './Services/Data/data.service';


declare var $: any;

declare var dayjs: any;
declare var LottiePlayer: any;



// import {} from '@types/googlemaps';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

count = 0;
currentRoute = '';
// variable store event
deferredPrompt : any;
hidebutton = true;
showopenaspwa = true;

prefersDark: any;
togglebutton: any;
showbackbutton = false;
backdropDismiss = false

userviewsbool = false;
riderviewsbool = false;
vendorviewsbool = false;
darkmodebool = true;

newWindow: any = navigator;
cart = 0;
user: any;
isModalOpen = false;
position: any;

autocomplete: any;
address1Field : any;

 priceper_km = 0;
 deliveryfee = 0;
 total = 0;
 cartdetailsfull: any;
  cachecount = 0;
  showloactionspinner = false;
  adminlocationbool = false;
  requestlocation: any;

   analytics = {
    message : "message",
    who : 'Rider/User',
    who_id : "",
    page : 'login page',
    action : 'login',
    type : 'install'
  }

  deliverytypeobject = {
    type : '',
    datetime : '',
    message : ''
  }

  pickuptypeobject = {
    now : 'light',
    later : 'light'
  }

  km: any = 0;

  checked = 'custom';
  dateselected: any;
  isOpen = false;
  now: any;
  timediffernece = {
    now : false,
    later : false,
    diff: 0,
    diffstring: '',
    format: 'mins',
     type :'',
     datetime: '',
     message: '',
     number : '',
     pickupfee : 0

  }
 @ViewChild('location_box') addresstext: any;
 type = '';
 vendormessage = '';
 vendor_percentage = 0;
 itemfee = 0;
 oldcount = 0;
 newcartcount = 0;
 button_data: any = {};
 canDismiss = true;
 someJSONdata: any



 //

 subscription: any;
 isopenDate: boolean = false;

  constructor(private router: Router, private alertController: AlertController, private datePipe: DatePipe, private ngZone: NgZone, private platform: Platform, public menuCtrl: MenuController, private location: Location, private share: DataService, private api: ApiService, private route: ActivatedRoute) {



  this.reloadPWA()



   this.subscription =  this.share.getAlertButton.subscribe((data: any) => {

     console.log(data);
     
     if(data?.status == true ) {

      this.button_data = data;

      if(this.button_data?.type == 'order') {

        this.canDismiss = false;
      }

      $("#open-modal").click();

     }

  });


  this.share.getShareObjectMsg.subscribe((response: any) => { 

     console.log(response);
    if(response.type == 'searchfilter') {
      this.isopenDate = true
    }

  });
  

  }

  ngOnInit(): void {

    
    this.checkPlatform()
    // this.preloadTheme()
    this.loadAppnew()
    this.menuitems()



  }






  ngAfterViewInit() {

    this.pwaMessage();

  }

  toogleMenu(){
    this.menuCtrl.toggle();
  }

  goBack() {

    this.location.back();

  }



  




 focusClick() {
  console.log('focuesd')

  setTimeout(() => {
    // this.initAutocomplete()
  }, 500);
 }





  logOut() {
    window.location.href = "";

    this.themeLogout();
  }



  themeLogout() {

     // check if username is set dont delete it .. get it before below runs then at the bottom save iot back if it exist;
    var username = localStorage.getItem('username');
    var dvid = localStorage.getItem('dvid');

    var notification_count = (localStorage.getItem('notification_count') as any);
    var notification = localStorage.getItem('notification');

   var oisstandalone = localStorage.getItem('oisstandalone');
   var pickupanimation = localStorage.getItem('pickupanimation');

   var installdeniedcount =  localStorage.getItem('installdeniedcount');
   var randset =  localStorage.getItem('randset');
   var sound =  localStorage.getItem('sound');

    if(localStorage.getItem('theme')) {
      var theme = localStorage.getItem('theme');

       if(theme == 'dark') {

        localStorage.clear();

        document.body.classList.add('dark');
        // this.darkmodebool = false;
        localStorage.setItem('theme','dark');

       }
       else {

        localStorage.clear();

        document.body.classList.add('light');
        localStorage.setItem('theme','light');

       }
    }
    else {

      localStorage.clear();
      document.body.classList.add('dark');
      localStorage.setItem('theme','dark');

    }
    

    // save username u got at the top after code above had run

    if(username) {
      localStorage.setItem('username',username);
    }

    if(notification_count) {
      localStorage.setItem('notification_count',notification_count+"");
    }

    if(notification) {
      localStorage.setItem('notification',notification);
    }

    if(oisstandalone) {
      localStorage.setItem('oisstandalone',oisstandalone);
    }
    if(pickupanimation) {
      localStorage.setItem('pickupanimation',pickupanimation);
    }

    if(installdeniedcount) {
      localStorage.setItem('installdeniedcount',installdeniedcount);
    }

    if(randset) {
      localStorage.setItem('randset',randset);
    }
    if(sound) {
      localStorage.setItem('sound',sound);
    }






  }


  menuitems() {

    $( ".menuitem" ).click((event: any) =>{
      // console.log(event.currentTarget.id)
      // alert( "Handler for .click() called." );
      $( ".menuitem"+' ion-label' ).removeClass('menuitemcolor')
      $( "#"+event.currentTarget.id+' ion-label' ).addClass('menuitemcolor')

      this.toogleMenu()
    });
  }


  subscribeToUser() {

     console.log("called get user")
    if(localStorage.getItem('who')) {

      var who: any = localStorage.getItem('who');
      who = JSON.parse(who);

      if(who?.user == 'vendor') {

        this.user = who;
        this.share.setShareObjectMsg(who)

      }

      if(who?.user == 'admin') {

        this.api.getRider().then((data: any) => {

         console.log(data)

          if(data.user){

           this.user = data.user;
           this.share.setShareObjectMsg(data)

           }
          else {
           this.router.navigateByUrl('login').then(data => {
             // localStorage.clear()
             localStorage.removeItem('k')
           })
          }

        })
     }



    }
    else {

      this.router.navigateByUrl('login').then(data => {
        localStorage.removeItem('k')
      })

    }


    // this.share.getShareObjectMsg.subscribe(data => {
    //   console.log(data)

    // })
  }





  setToStorage() {

    var storage = this.share.getStoraage();

     if(storage != null) {


     }
      else {

        this.router.navigateByUrl('login').then(data => {

          console.log(storage)
        })

      }
  }



  // createStorageDEtails() {

  //   var storage = this.share.getStoraage();

  //   if(storage == null) {

  //     localStorage.setItem('booking','{}');
  //   }

  // }





  reloadPWA() {


     this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
          // Show progress spinner or progress bar
          // console.log('Route change detected');
          this.showbackbutton = false;
          this.share.setHamburgerMenutMsg(this.showbackbutton)
          this.InterceptInstallPrompt()
      }

      if (event instanceof NavigationEnd) {

        // setTimeout(() => {
        //   this.InterceptInstallPrompt()
        // }, 5000);

        this.currentRoute = event.url;

        // prevent show of menu in login page
        if(this.currentRoute.includes('login')) {
          this.menuCtrl.enable(false);
          //  this.createStorageDEtails();
          // alert('checkout');
        }


         // allow toolbar only in userview
        if(this.currentRoute.includes('user/tabs/')) {

          this.userviewsbool = true;
          // alert('checkout');
        }

        // allow toolbar only in riderview
        if(this.currentRoute.includes('rider/tabs/')) {

          this.riderviewsbool = true;
          // alert('checkout');
        }

        // allow toolbar only in vendorview
        if(this.currentRoute.includes('vendor/tabs/')) {

          this.vendorviewsbool = true;
          // alert('checkout');
        }




        // hide back button wheere necesarry;

       if(!this.currentRoute.includes('/tabs/home')) {

          console.log('yes')
          this.showbackbutton = true;
          this.share.setHamburgerMenutMsg(this.showbackbutton)
          // alert('checkout');
        }


        if(this.currentRoute == '') {


          if (window.matchMedia('(display-mode: standalone)').matches) {
            // alert('display-mode is standalone');

            // window.addEventListener("visibilitychange", function () {
            //   console.log("Visibility changed");
            //   if (document.visibilityState === "visible") {
            //     console.log("APP resumed");
            //     window.location.reload();
            //   }
            // });


              if (document.visibilityState === "visible") {
                console.log("APP resumed");

                // alert('display-mode is standalone');

                window.location.reload();

                window.stop();

              }



          }
          else {

            if(this.count < 1) {

              // window.addEventListener("visibilitychange", function () {
              //   console.log("Visibility changed");
              //   if (document.visibilityState === "visible") {
              //     console.log("APP resumed");
              //     window.location.reload();
              //   }
              // });


                if (document.visibilityState === "visible") {
                  console.log("APP resumed");

                  // alert('display-mode is not standalone');

                  window.location.reload();
                  window.stop();


                }


            }
            this.count = 1
          }



        }



        if(!this.currentRoute.includes('signup') && !this.currentRoute.includes('reset') && !this.currentRoute.includes('login') && !this.currentRoute.includes('vendor/login')) {
          console.log("I got here 2")
          // this.setToStorage()
          this.subscribeToUser();
        }


         if(window.location.href.includes('login') || window.location.href.includes('vendor/login')){
          // console.log(window.location.href)
          this.clearServiceWrokerCache()
         }



         if(window.location.href.includes('vendor/login')) {

          this.presentAlert()

         }
      }


      if (event instanceof NavigationError) {
           // Hide progress spinner or progress bar

          // Present error to user
          // console.log(event.error);
      }
  });


  }




  async presentAlert() {

    const alert = await this.alertController.create({
      header: 'Hi, you are heading to vendor section!🔔',
      message: 'Do you want to login as a vendor or a user?',
      buttons: [
        {
          text: 'User',
          role: 'cancel',
          handler: () => {

           var who =  localStorage.getItem('who');
           if(who) {
            localStorage.removeItem('who');
           }
            window.location.href = 'login'

            console.log("cancelled")
          },
        },
        {
          text: 'Vendor',
          role: 'confirm',
          handler: () => {

            var who =  localStorage.getItem('who');
            if(who) {
             localStorage.removeItem('who');
            }
            this.share.setToastMsg({status:"medium" , message: "Alright, login as a vendor below", duration: 3000,position:'top'})

            // window.location.href = 'vendor/login';

            console.log("Allow");

          },
        },
      ],
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    // this.roleMessage = `Dismissed with role: ${role}`;
  }





  async presentAlert3() {

    const alert = await this.alertController.create({
      header: 'Yoo!, headsup!🔔',
      message: 'Kindly use a mobile device. Thanks',
      backdropDismiss: false,
      buttons: [
        // {
        //   text: 'User',
        //   role: 'cancel',
        //   handler: () => {

        //    var who =  localStorage.getItem('who');
        //    if(who) {
        //     localStorage.removeItem('who');
        //    }
        //     window.location.href = 'login'

        //     console.log("cancelled")
        //   },
        // },
        // {
        //   text: 'Continue',
        //   role: 'confirm',
        //   handler: () => {
        //     console.log("installed");
        //     window.location.reload();
        //   },
        // },
      ],
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    // this.roleMessage = `Dismissed with role: ${role}`;
  }



  async presentAlert2() {

    const alert = await this.alertController.create({
      header: 'App installed on your device!🔔',
      message: 'Please search Peral De Food on your device or continue using this web version',
      buttons: [
        // {
        //   text: 'User',
        //   role: 'cancel',
        //   handler: () => {

        //    var who =  localStorage.getItem('who');
        //    if(who) {
        //     localStorage.removeItem('who');
        //    }
        //     window.location.href = 'login'

        //     console.log("cancelled")
        //   },
        // },
        {
          text: 'Continue',
          role: 'confirm',
          handler: () => {
            console.log("installed");
            window.location.reload();
          },
        },
      ],
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    // this.roleMessage = `Dismissed with role: ${role}`;
  }


  pwaMessage() {
    this.share.getPWAMsg.subscribe((data: any) => {

      console.log("pwa message appcomponent")

      console.log(data)
      if(data.message == 'install') {
        console.log("pwa apcomponernt called")

        this.clickPrompt();

      }
    })
  }


  clearServiceWrokerCache() {
    console.log("service workwr cacvhe")


    if (navigator.onLine) {
      // alert('online');

      if ('serviceWorker' in navigator) {
        caches.keys().then((cacheNames) => {

          if(cacheNames.length > 1) {

            cacheNames.forEach((cacheName) =>{
              caches.delete(cacheName);
              console.log("service workwr cacvhe cleared:"+cacheName)

            });


            // alert('cleared cache count: '+cacheNames.length)

                // setTimeout(() => {
                //   window.location.reload()
                // }, 2000);

                this.cachecount = cacheNames.length;

          }

        });
      }


    } else {

      alert("You are offline");
    }


  }

  InterceptInstallPrompt() {




// get button with id
// const install_button: any = document.getElementById('installbutton');

// if the app can be installed emit beforeinstallprompt
window.addEventListener('beforeinstallprompt', (e:any) => {
  // this event does not fire if the application is already installed
  // then your button still hidden ;)

  this.share.setPWAMsg({
    'page': 'pwa',
    'message': 'androidinstallable'

  })

  console.log('fired before install')
  // show button with display:block;
  this.hidebutton = false;
  this.showopenaspwa = false;
  // prevent default event
  e.preventDefault();

  // store install avaliable event
  this.deferredPrompt = e;


});





// // if are standalone android OR safari
// if (window.matchMedia('(display-mode: standalone)').matches || this.newWindow.standalone === true) {
//   // hidden the button
//   this.hidebutton = true;
// }

// do action when finished install
window.addEventListener('appinstalled', (e:any) => {
  console.log("success app install!");
  this.showopenaspwa = true;
  this.hidebutton = true;
  // this.checkSendDeviceIID('install')

  this.presentAlert2();

  // alert('app installed successfully')
});


  }



  clickPrompt() {

      // wait for click install button by user

    this.deferredPrompt.prompt();
    this.deferredPrompt.userChoice.then((choiceResult: any) => {
      if (choiceResult.outcome === 'accepted') {
        // user accept the prompt
        console.log('User accepted the prompt');
        // alert('user accepted')
        // lets hidden button
      } else {
        console.log('User dismissed the prompt');
        alert('user denied installation, please install DoorBox')
      }
      this.deferredPrompt = null;
    });


  }







  checkPlatform() {

 this.platform.ready().then((data) => {

  console.log(data)
if (this.platform.is('android')) {
        console.log('android');
        // alert('android')
        if (this.platform.is('mobileweb')) {
          console.log("mobileweb")
        }

        if (this.platform.is('mobile')) {
          console.log("mobile")
        }

        if (this.platform.is('pwa')) {
          console.log("pwa")
        }

        if (this.platform.is('desktop')) {
          console.log("desktop")
        }

        if (this.platform.is('hybrid')) {

          console.log("hybrid")
        }


        // if are standalone android OR safari
if (window.matchMedia('(display-mode: standalone)').matches) {
  // hidden the button
  console.log('standalone, android');
  this.showopenaspwa = false;

}else {

  // this.showopenaspwa = true;
  console.log('not standalone, android');
  // this.InterceptInstallPrompt();

}


   } else if (this.platform.is('ios')) {

                // if are standalone android OR safari
if (this.newWindow.standalone === false) {
  // hidden the button
  // this.hidebutton = true;
  this.showopenaspwa = false;
  this.share.setPWAMsg({
    'page': 'pwa',
    'message': 'iosinstallable'

  })

  // alert('Not a standalone ios app, please use safari then add the app to homescreen, if you havent')
}else {

  localStorage.setItem('oisstandalone', 'true');
  this.showopenaspwa = false;

}

        // alert('alert ios')
   }

   else if (this.platform.is('desktop')) {

    this.presentAlert3();

  }


   else {
        //fallback to browser APIs or
        // alert('alert web')
console.log('The platform is not supported, web');

              // if are standalone android OR safari
if (window.matchMedia('(display-mode: standalone)').matches) {
  // hidden the button
  console.log('standalone, web');

  this.showopenaspwa = false;

}else {
  console.log('not standalone, web');
  // this.showopenaspwa = true;
  // this.InterceptInstallPrompt();

}


          }

      })

  }







  Clickthemenew() {

    if(localStorage.getItem('theme')) {
      var theme = localStorage.getItem('theme');

       if(theme == 'dark') {

        document.body.classList.remove('dark');
        this.darkmodebool = false;
        localStorage.setItem('theme','light');

       }
       else {

        this.darkmodebool = true;
        document.body.classList.add('dark');
        localStorage.setItem('theme','dark');

       }
    }
    else {

      this.darkmodebool = true;
      document.body.classList.add('dark');
      localStorage.setItem('theme','dark');

    }

  }


  closeModal() {

    this.isModalOpen = false;

     if(this.currentRoute.includes("checkout")) {

      setTimeout(() => {
        window.location.href = "/user/tabs/vendors";
      }, 1000);

     }

  }






  preloadTheme() {

      // Query for the toggle that is used to change between themes
 var togglebutton: any = document.querySelector('#themeToggle');


// Listen for the toggle check/uncheck to toggle the dark class on the <body>
 togglebutton?.addEventListener('ionChange', (ev: any) => {
  document.body.classList.toggle('dark', ev.detail.checked);

   if(ev.detail.checked == true) {
    localStorage.setItem('theme','dark');
   }
   else {
    localStorage.setItem('theme','light');
   }
  console.log(ev.detail.checked)

});

// this.checkToggle(prefersDark.matches)
// console.log(prefersDark)
 const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
 console.log(prefersDark)
// Listen for changes to the prefers-color-scheme media query
prefersDark.addEventListener("onchange", (e: any) => {
  console.log(e)

  // get the new prefrences for dark if true means user toghled for dark if false use toglled for no dark.. that is user removed class dark
  this.checkToggle(togglebutton, e.matches)
});

// default on app load
this.loadApp(togglebutton)

  }


  loadAppnew() {

    if(localStorage.getItem('theme')) {
      var theme = localStorage.getItem('theme');

       if(theme == 'dark') {
        document.body.classList.add('dark');
        this.darkmodebool = true;

       }
       else {
        this.darkmodebool = false;
        document.body.classList.remove('dark');


       }
    }
    else {
      document.body.classList.add('dark');
      this.darkmodebool = true;

    }

  // if(!document.body.classList.contains('dark')) {
  //   document.body.classList.add('dark');
  //   // on load set the deafult scheme to dark and toggle the button to true
  // this.checkToggle(button, true);
  // }

}




// Called when the app loads
 loadApp(button: any) {

    if(localStorage.getItem('theme')) {
      var theme = localStorage.getItem('theme');

       if(theme == 'dark') {
        document.body.classList.add('dark');
        this.darkmodebool = true;
        this.checkToggle(button, true);
       }
       else {
        this.darkmodebool = false;
        document.body.classList.remove('dark');
        this.checkToggle(button, false);

       }
    }
    else {
      document.body.classList.add('dark');
      this.darkmodebool = true;
      this.checkToggle(button, true);
    }

}

// set the toggle button to the slected state , true for dark mode false or light mode
 checkToggle(button: any, shouldCheck: any) {

  button.checked = shouldCheck;
}





presentPopover() {

  //  console.log(event.target.checked)
  //  if(event.target.checked == true) {
  //   this.isOpen = true;
  //   this.pickerbool = true;
  //   return
  //  }
 
   this.isOpen = true;

  //  this.dateselected  = "Shedule Order";
  //  this.isOpen = false;

}



selectedDate(event: any) {


var dateFromIonDatetime: any = this.datePipe.transform(event.detail.value, 'yyyy-MM-dd HH:mm:ss')

 var now = dayjs()
 console.log(dateFromIonDatetime)

//  const date2 = dayjs(this.dateselected)
this.share.getShareObjectMsg.pipe(
  take(1),
).subscribe((data: any) => {

      if(data.type == 'searchfilter') {
        this.share.setShareObjectMsg({type:'searchfilter',date:dateFromIonDatetime})
      }else {
        this.share.setShareObjectMsg({type:'search',date:dateFromIonDatetime, to: 'home'})
      }
      this.isopenDate = false;

})


}



//////////////////

alertButtonClick(modal: IonModal, status: boolean, where: string): void {

   if(where == 'others') {

    this.share.setAlertMsg({status: status});
    if(this.button_data?.type != 'order' && this.button_data?.type != 'change') {
      modal.dismiss();
      this.canDismiss =  true;
    }
    else {
    
    }
   
   }
   else {

    modal.dismiss();
    this.canDismiss =  true;
    // modal.backdropDismiss = false; 

   }
  // this.subscription.unsubscribe();

  
}

presentControlDate() {
 this.isopenDate = true;
 this.share.setShareObjectMsg({type: 'search', to: 'order'})
}

printDocument(modal: any, data: any) {

  this.someJSONdata = [
    {
       name: 'John Doe',
       email: 'john@doe.com',
       phone: '111-111-1111'
    },
    {
       name: 'Barry Allen',
       email: 'barry@flash.com',
       phone: '222-222-2222'
    },
    {
       name: 'Cool Dude',
       email: 'cool@dude.com',
       phone: '333-333-3333'
    }
 ]


//  printJs({ printable: 'printme', type: 'html', header: 'PrintJS - Form Element Selection' })

 printJs({printable: this.someJSONdata, properties: ['name', 'email', 'phone'], type: 'json'});


}




}
