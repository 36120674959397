import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private jwtHelper = new JwtHelperService();
  constructor() { }


  public isAuthenticated(): boolean {
    // Check whether the token is expired and return
    // true or false
    const token: any = localStorage.getItem('k');
    return !this.jwtHelper.isTokenExpired(token);

  }


}
