import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SplitemailPipe } from 'src/app/pipe/splitemail.pipe';


@NgModule({
  declarations: [SplitemailPipe],
  imports: [
    CommonModule
  ],
  providers: [DatePipe],
  exports: [SplitemailPipe]
})
export class MinisharedModule { }
