import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

declare var dayjs: any;

@Injectable({
  providedIn: 'root'
})
export class DataService {


  toast: any;

  private toastMsg: BehaviorSubject<[]> = new  BehaviorSubject([]);
  private authMsg = new  BehaviorSubject('');

  private shareSingleMsgObj: BehaviorSubject<[]> = new  BehaviorSubject([]);

  private hamburgerMenuMsg = new  BehaviorSubject(false);
  private shareObjectMsg: BehaviorSubject<[]> = new  BehaviorSubject([]);

  private shareCart: BehaviorSubject<[]> = new  BehaviorSubject([]);

  private shareDataMsg: BehaviorSubject<[]> = new  BehaviorSubject([]);


  private pwaDataMsg: BehaviorSubject<[]> = new  BehaviorSubject([]);


  private alertMsg = new  BehaviorSubject({});
  private alertButton = new  BehaviorSubject({});

  constructor(public toastController: ToastController) { }




getNotifyMsg = this.toastMsg.asObservable();  // by subscribing
setToastMsg(message: any) {
this.toastMsg.next(message)
// console.log(message)

//  if(message.show == true) {
//   this.toastMsgCreate(message?.status,message?.title)
//  }

//  if(message.show == false) {
//   this.toast.dismiss();
//  }
this.toastMsgCreate(message?.message,message?.duration,message?.position, message?.status)


}


async toastMsgCreate(message: string, duration: number, position: any, color: string) {
  this.toast = await this.toastController.create({
    message: message,
    duration: duration,
    position: position,
    color: color
  });
  this.toast.present();
}


getPWAMsg = this.pwaDataMsg.asObservable();  // by subscribing
setPWAMsg(message: any) {
this.pwaDataMsg.next(message)
console.log(message)
}

getHamburgerMenutMsg = this.hamburgerMenuMsg.asObservable();  // by subscribing
setHamburgerMenutMsg(message: boolean) {
this.hamburgerMenuMsg.next(message)
console.log(message)
}

getCarttMsg = this.shareCart.asObservable();  // by subscribing
setCartMsg(message: any) {
this.shareCart.next(message)
console.log(message)
}

getShareObjectMsg = this.shareObjectMsg.asObservable();  // by subscribing
setShareObjectMsg(message: any) {
this.shareObjectMsg.next(message)
console.log(message)
}

getShareDataMsg = this.shareDataMsg.asObservable();  // by subscribing
setshareDataMsg(message: any) {
this.shareDataMsg.next(message)
console.log(message)
}

getAuth = this.authMsg.asObservable();  // by subscribing
setAth(message: string) {
this.authMsg.next(message)
localStorage.setItem('k', message);
}


getSingleMsgObj = this.shareSingleMsgObj.asObservable();  // by subscribing
setSingleMsgObj(message: any) {
this.shareSingleMsgObj.next(message)
}

getAlertMsg = this.alertMsg.asObservable();  // by subscribing
setAlertMsg(message: any) {
this.alertMsg.next(message)
}

getAlertButton = this.alertButton.asObservable();  // by subscribing
setAlertButton(message: any) {
this.alertButton.next(message)
}






getStoraage() {

  var jsonstr: any = localStorage.getItem('booking');
 return  jsonstr ? JSON.parse(jsonstr): null ;

}

setStorage(details: any) {

  localStorage.setItem('booking', JSON.stringify(details) );

}


 checkCacheV2(cachedatatype: string) {
  
  var cacheStorage: any = localStorage.getItem('cachestorage')

  var response = null;
  if(cacheStorage) {

    cacheStorage = JSON.parse(cacheStorage);
    

    if(cachedatatype == 'update_vendors') {

      let data = cacheStorage.find( (x: any)=> x.id == cachedatatype );
      if(data) {
        response = data
      }
    }

    if(cachedatatype == 'update_vendor_menus') {

      console.log("got to vendor menus")
      let data = cacheStorage.find( (x: any)=> x.id == cachedatatype );
      if(data) {

         var getPartner: any = localStorage.getItem('booking')
     
          if(getPartner) {
            getPartner = JSON.parse(getPartner);

             if(getPartner.n) {

              console.log("found partner")
              let vendormenusarray = data?.data.find( (x: any)=> x.id == getPartner?.n?.id );

           
               if(vendormenusarray) {

                response = vendormenusarray

               }
             
          
             }
          }
   
      }
      else {
        console.log("found no data")
      }

    }



    if(cachedatatype == 'update_vendor_menuitems') {

      console.log("got to vendor menuitems")
      let data = cacheStorage.find( (x: any)=> x.id == cachedatatype );
      if(data) {

         var getPartner: any = localStorage.getItem('booking')
     
          if(getPartner) {
            getPartner = JSON.parse(getPartner);

             if(getPartner.n) {

              console.log("found partner")
              let vendormenusarray = data?.data.find( (x: any)=> x.id == getPartner?.n?.id );

           
               if(vendormenusarray) {

                response = vendormenusarray

               }
             
          
             }
          }
   
      }
      else {
        console.log("found no data")
      }

    }

    // console.log("point one")

 

  }

   console.log("response is"+response)
  return response;
 }


 checkCache(type: string) {

   var response = false;
   var cacheupdate: any = localStorage.getItem('cacheupdate')

    if(!cacheupdate) {

      console.log("point one")
      response = false;

    }

    else {

      if(type == 'update_vendors') {

        cacheupdate = JSON.parse(cacheupdate);

        if(cacheupdate?.update_vendors == null) {

          console.log("point two")
          response = false;

        } else {

          var findincache: any = localStorage.getItem('findincache');

          if(!findincache) {

            console.log("no find in cache")
            response = false;
      
          }
          else {

            findincache = JSON.parse(findincache);

             if(findincache?.vendors) {

              var now = dayjs()
              //  if() {
              //   console.log("point three")
               
              //   console.log(now)
      
              //  }
              //  else {

              //  }
              response = true;
             }
             else {
              response = false
             }

          }


        }
        
  
       }
 
    }

 
   return response;

 }


 useCache(type: string) {

  var response = false;
  if(type == 'update_vendor') {

   response = true;
  }

  return true;
}

 addToCache(type: string, externaldata: any) {

  // if(type == 'update_vendor') {

  // }

    var cacheStorage: any = localStorage.getItem('cachestorage');

    var obj = {
      id: type,
      data: externaldata
    }
    var getPartner: any = localStorage.getItem('booking')

    getPartner = JSON.parse(getPartner);

    if(cacheStorage) {

    cacheStorage = JSON.parse(cacheStorage);


     if(type == 'update_vendors') {

      let index = cacheStorage.findIndex( (x: any)=> x.id == type );

      if (index > -1) { // only splice array when item is found
        cacheStorage.splice(index, 1); // 2nd parameter means remove one item only
        cacheStorage.push(obj)
        localStorage.setItem('cachestorage', JSON.stringify(cacheStorage));
        
      }
       else {
  
        cacheStorage.push(obj)
        localStorage.setItem('cachestorage', JSON.stringify(cacheStorage));
  
       }
       
     }


     if(type == 'update_vendor_menus') {

       console.log("got to update menus save")
      let data = cacheStorage.find( (x: any)=> x.id == type );
      let update_vendor_menus_index = cacheStorage.findIndex( (x: any)=> x.id == type );
    
     
      if(data && update_vendor_menus_index > -1) {

        console.log("got to update menus save");

         
          if(getPartner) {
           

             if(getPartner.n) {

              var innerdata = data?.data;
              let index = innerdata.findIndex( (x: any)=> x.id == getPartner?.n?.id );


              if(index > -1) {

                innerdata[index].data = externaldata;
               
                // innerdata.splice(index, 1); // 2nd parameter means remove one item only
                // innerdata.push(innerobj);
              

                // let update_vendor_menus_index = cacheStorage.findIndex( (x: any)=> x.id == type );

                // if(update_vendor_menus_index > -1) {

                
                // }

                // update the general cache storage
                // obj.id = type;
                // obj.data = innerdata
                // we already know the id from tope which is type
                cacheStorage[update_vendor_menus_index].data = innerdata
                
                // cacheStorage.data = innerdata;
                localStorage.setItem('cachestorage', JSON.stringify(cacheStorage));

              } else {

                var objj = {
                  id: getPartner?.n?.id,
                  data: externaldata
                }
                innerdata.push(objj)

                // obj.id = type;
                // obj.data = innerdata
                    // we already know the id from tope which is type
                cacheStorage[update_vendor_menus_index].data = innerdata
  
                localStorage.setItem('cachestorage', JSON.stringify(cacheStorage));
          
              }
         

             }
          }

        

      }
      else {

        // obj.id = type // we already know

        // create new partner menus obj
        console.log("got to adding data")
        console.log(getPartner?.n?.id)
        var partnerobj1 = {
          id: getPartner?.n?.id,
          data: externaldata
        } 
        // push it into an array

        var partnerarry: any = [];
        partnerarry.push(partnerobj1);
        // append the array to obj data above we already know the type which is the type we passed in
    
        obj.data = partnerarry

        console.log(obj)

        cacheStorage.push(obj)
        localStorage.setItem('cachestorage', JSON.stringify(cacheStorage));
      }

      // let index = cacheStorage.findIndex( (x: any)=> x.id == type );

      // if (index > -1) { // only splice array when item is found
      //   cacheStorage.splice(index, 1); // 2nd parameter means remove one item only
      //   cacheStorage.push(obj)
      //   localStorage.setItem('cachestorage', JSON.stringify(cacheStorage));
        

      //   let index = cacheStorage.findIndex( (x: any)=> x.id == type );


      // }
      //  else {
  
      //   cacheStorage.push(obj)
      //   localStorage.setItem('cachestorage', JSON.stringify(cacheStorage));
  
      //  }

     }




     if(type == 'update_vendor_menuitems') {

      console.log("got to update menusitems save")
     let data = cacheStorage.find( (x: any)=> x.id == type );
     let update_vendor_menuitem_index = cacheStorage.findIndex( (x: any)=> x.id == type );
   
    
     if(data && update_vendor_menuitem_index > -1) {

       console.log("got to update menusitems save");

        
         if(getPartner) {
          

            if(getPartner.n) {

             var innerdata = data?.data;
             let index = innerdata.findIndex( (x: any)=> x.id == getPartner?.n?.id );


             if(index > -1) {

               innerdata[index].data = externaldata;
              
               // innerdata.splice(index, 1); // 2nd parameter means remove one item only
               // innerdata.push(innerobj);
             

               // let update_vendor_menus_index = cacheStorage.findIndex( (x: any)=> x.id == type );

               // if(update_vendor_menus_index > -1) {

               
               // }

               // update the general cache storage
               // obj.id = type;
               // obj.data = innerdata
               // we already know the id from tope which is type
               cacheStorage[update_vendor_menuitem_index].data = innerdata
               
               // cacheStorage.data = innerdata;
               localStorage.setItem('cachestorage', JSON.stringify(cacheStorage));

             } else {

               var objj = {
                 id: getPartner?.n?.id,
                 data: externaldata
               }
               innerdata.push(objj)

               // obj.id = type;
               // obj.data = innerdata
                   // we already know the id from tope which is type
               cacheStorage[update_vendor_menuitem_index].data = innerdata
 
               localStorage.setItem('cachestorage', JSON.stringify(cacheStorage));
         
             }
        

            }
         }

       

     }
     else {

       // obj.id = type // we already know

       // create new partner menus obj
       console.log("got to adding data")
       console.log(getPartner?.n?.id)
       var partnerobj1 = {
         id: getPartner?.n?.id,
         data: externaldata
       } 
       // push it into an array

       var partnerarry: any = [];
       partnerarry.push(partnerobj1);
       // append the array to obj data above we already know the type which is the type we passed in
   
       obj.data = partnerarry

       console.log(obj)

       cacheStorage.push(obj)
       localStorage.setItem('cachestorage', JSON.stringify(cacheStorage));
     }

     // let index = cacheStorage.findIndex( (x: any)=> x.id == type );

     // if (index > -1) { // only splice array when item is found
     //   cacheStorage.splice(index, 1); // 2nd parameter means remove one item only
     //   cacheStorage.push(obj)
     //   localStorage.setItem('cachestorage', JSON.stringify(cacheStorage));
       

     //   let index = cacheStorage.findIndex( (x: any)=> x.id == type );


     // }
     //  else {
 
     //   cacheStorage.push(obj)
     //   localStorage.setItem('cachestorage', JSON.stringify(cacheStorage));
 
     //  }

    }

   }
   else {

    
     if(type == 'update_vendor_menus'){
        
      console.log("got to adding data2")
      var partnerobj = {
        id: getPartner?.n?.id,
        data: externaldata
      } 
      // push it into an array
      var partnerarry: any = [];
      partnerarry.push(partnerobj);

      // append the array to obj data above we already know the type which is the type we passed in
     obj.data = partnerarry
     // cacheStorage = JSON.parse(cacheStorage);
     localStorage.setItem('cachestorage', JSON.stringify(cacheStorage));
     var  getcachestorage: any  = localStorage.getItem("cachestorage");
     getcachestorage = JSON.parse(getcachestorage);
     getcachestorage.push(obj)
     localStorage.setItem('cachestorage', JSON.stringify(cacheStorage));
    }

    if(type == 'update_vendor_menuitems'){
        
      console.log("got to adding data2 menuitems")
      var partnerobj = {
        id: getPartner?.n?.id,
        data: externaldata
      } 
      // push it into an array
      var partnerarry: any = [];
      partnerarry.push(partnerobj);

      // append the array to obj data above we already know the type which is the type we passed in
     obj.data = partnerarry
     // cacheStorage = JSON.parse(cacheStorage);
    localStorage.setItem('cachestorage', JSON.stringify(cacheStorage));
     var  getcachestorage: any  = localStorage.getItem("cachestorage");
     getcachestorage = JSON.parse(getcachestorage);
     getcachestorage.push(obj)
     localStorage.setItem('cachestorage', JSON.stringify(cacheStorage));
    }

      
      else {

        var cacheStorage: any = [];
        cacheStorage.push(obj)
        localStorage.setItem('cachestorage', JSON.stringify(cacheStorage));

        
      }
   }

}


removeFromCache(type: string) {

  if(type == 'update_vendor') {

  }

}



getUser() {

  var jsonstr: any = localStorage.getItem('who');
  if(jsonstr) {
    return JSON.parse(jsonstr);
  }else {

    window.location.href = 'login';

  }

}



}
