import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { DashboardPage as UserDashboard } from './pages/userdashboard/dashboard/dashboard.page';
import { DashboardPage  as VendorDashboard} from './pages/vendordashboard/dashboard/dashboard.page';

const routes: Routes = [

  // {
  //   path: '',
  //   loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  // },
  //  {
  //   path: 'app',
  //   loadChildren: () => import('./pages/userdashboard/tabs/tabs.module').then(m => m.TabsPageModule)
  // },
  // {
  //   path: 'splash',
  //   loadChildren: () => import('./pages/splash/splash.module').then( m => m.SplashPageModule)
  // },
  // {
  //   path: 'dashboard',
  //   loadChildren: () => import('./pages/userdashboard/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  // },
  // {
  //   path: 'dashboard',
  //   loadChildren: () => import('./pages/riderdashboard/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  // }



  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },

  {
    path: 'signup',
    loadChildren: () => import('./pages/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'reset',
    loadChildren: () => import('./pages/reset/reset.module').then( m => m.ResetPageModule)
  },

{ path: '', pathMatch: 'full', redirectTo: 'login' },


{ path: 'user',
  component: UserDashboard,
// loadChildren: () => import('./pages/userdashboard/dashboard/dashboard.module').then(m => m.DashboardPageModule),

  children: [
    { path: 'ddhd', loadChildren: () => import('./pages/userdashboard/dashboard/dashboard.module').then(m => m.DashboardPageModule) },
    { path: 'tabs', loadChildren: () => import('./pages/userdashboard/tabs/tabs.module').then(m => m.TabsPageModule) },
   
    // { path: 'pay', loadChildren: () => import('./users/pay/pay.module').then(m => m.PayModule) },
  ]

},

{ path: 'vendorr',

   component: VendorDashboard,
  // loadChildren: () => import('./pages/vendordashboard/dashboard/dashboard.module').then(m => m.DashboardPageModule),
  children: [
    { path: 'ddhdg', loadChildren: () => import('./pages/vendordashboard/dashboard/dashboard.module').then(m => m.DashboardPageModule) },
    { path: 'login', loadChildren: () => import('./pages/vendordashboard/login/login.module').then(m => m.LoginPageModule)},

    { path: 'tabs', loadChildren: () => import('./pages/vendordashboard/tabs/tabs.module').then(m => m.TabsPageModule) },
    // { path: 'pay', loadChildren: () => import('./users/pay/pay.module').then(m => m.PayModule) },
  ]

},




  {
    path: 'reset',
    loadChildren: () => import('./pages/reset/reset.module').then( m => m.ResetPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/vendordashboard/home/home.module').then( m => m.HomePageModule)
  },

  {
    path: 'dashboard',
    loadChildren: () => import('./pages/vendordashboard/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/vendordashboard/profile/profile.module').then( m => m.ProfilePageModule)
  },

  {
    path: 'login',
    loadChildren: () => import('./pages/vendordashboard/login/login.module').then( m => m.LoginPageModule)
  }




// canActivate: [AuthGuard]
  // tslint:disable-next-line: max-line-length
  // { path: '', component: LayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) },
  // { path: 'pages', loadChildren: () => import('./extrapages/extrapages.module').then(m => m.ExtrapagesModule)},
  // { path: '**', component: Page404Component },

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
