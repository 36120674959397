



<!-- <ion-header class="ion-no-border">
  <ion-toolbar >

    <ion-buttons (click)="toogleMenu()" slot="start">
      <ion-button>
        <ion-icon  name="menu-outline" class="iconsize"></ion-icon>
      </ion-button>
    </ion-buttons>




    <ion-button fill="clear" size="large" slot="end" color="dark" class="cartbox" href="user/tabs/checkout">

      <small class="itemcount">3</small>
      <ion-icon name="cart-outline"></ion-icon>
    </ion-button>



    <ion-thumbnail slot="end">
      <img alt="Silhouette of mountains" src="assets/img/someerrands.jpg" />
    </ion-thumbnail>


  </ion-toolbar>
</ion-header> -->

<ion-content>


  <!-- hey dashnboards -->


  <ion-router-outlet></ion-router-outlet>

  <!-- <app-tabs></app-tabs> -->

</ion-content>
